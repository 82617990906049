import React from "react";

import { Box, Button, Container, Grid, Typography } from "@mui/material";

import { Layout } from "layouts/layout";
import { graphql, HeadFC, HeadProps, Link, PageProps } from "gatsby";
import { SectionHeader } from "components/layout/section-header";
import { ArrowForwardRounded } from "@mui/icons-material";
import { SectionParagraph } from "components/layout/section-paragraph";
import { ServiceCard } from "content/cards/service-card";
import { MemberCard } from "components/cards/member-card";
import { PageMetadata } from "components/layout/page-metadata";
import { Content } from "models/schemas/content";
import { Member } from "models/schemas/member";
import { GraphQlArray } from "models/graphql/graphql-array";

export type AboutProps = {
    page: {
        contents: Content[]
    }
    members: GraphQlArray<Member>,
}

const About: React.FC<PageProps<AboutProps>> = ({ data }) => {

    
    console.log(data)
    
    const { page,  members } = data;
    const introduction = page.contents.filter(c => c.tags.includes("Introduction"))
    const values = page.contents.filter(c => c.tags.includes("Values"))
    const team = page.contents.filter(c => c.tags.includes("Team"))
    const join = page.contents.filter(c => c.tags.includes("Join"))

    return (
        <Layout>
            <Container maxWidth={'md'}>
                <Grid container spacing={10}>
                    <Grid item container>
                        <SectionHeader
                            title={"About Us"}
                            gutterTop
                        />
                    </Grid>
                    <Grid item container spacing={4}>
                        {introduction.map(content => (
                            <Grid item xs={12} key={content.heading}>
                                <SectionParagraph
                                    key={content.heading}
                                    title={content.heading}
                                    content={content.richText.markdown}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item container alignItems={"center"} justifyContent={"center"} spacing={2}
                          position={"relative"}>
                        <Box className={"radial-gradient"}/>
                        <Grid item>
                            <Typography variant={"h6"} color={"textSecondary"}>OUR VALUES</Typography>
                        </Grid>
                        <Grid item container spacing={2}>
                            {values.map(content => (
                                <Grid item xs={12} sm={6} md={4} key={content.heading}>
                                    <ServiceCard title={content.heading ?? ""} description={content.richText.markdown}/>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item container spacing={8}>
                        {team.map(content => (
                            <Grid item xs={12} key={content.heading}>
                                <SectionParagraph
                                    title={content.heading}
                                    content={content.richText.markdown}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item container spacing={2} alignItems={"stretch"}>
                        {members.nodes.map((member: Member) => (
                            <Grid item xs={12} sm={6} md={4} key={`${member.firstName} ${member.surname}`}>
                                <MemberCard
                                    member={member}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item container spacing={2} alignItems={"stretch"}>
                        {join.map(content => (
                            <Grid item xs key={content.heading}>
                                <SectionParagraph
                                    key={content.heading}
                                    title={content.heading}
                                    content={content.richText.markdown}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item container justifyContent={"center"}>
                        <Grid item position={"relative"}>
                            <Box className={"radial-gradient"} height={1000}/>
                            <Button className={"animated-gradient"} variant={"contained"} color={"primary"} size={"large"} endIcon={<ArrowForwardRounded/>} component={Link} to={"/company/careers"}>
                                <Typography fontSize={"large"}>Careers at <span style={{fontWeight: "bold"}}>Stackly</span></Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    )
}
export default About;

export const Head: HeadFC = (props: HeadProps<any>) => (
    <PageMetadata location={props.location.pathname} metadata={props.data.page.metadata}/>
)

export const query = graphql`
{
    page: graphCmsPage(identifier: { eq: "about" }) {
        metadata {
            title
            description
            thumbnail {
              url
            }
            embedImage {
              url
            }
            jsonLD
        }
        contents {
            heading
            richText {
              markdown
            }
            tags
        }
    }
    members: allGraphCmsMember {
        nodes {
            firstName
            surname
            role,
            image {
                  alt
                  url
            }
            biography {
                  markdown
            }
            awards {
                  url
                  dateAwarded
                  certification {
                    name
                    description
                    emblem {
                          alt
                          url
                    }
                    url
                  }
            }
        }
    }
}`