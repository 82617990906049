import { Button, Grid, Typography } from "@mui/material";
import { GlowCard } from "components/layout/glow-card";
import React from "react";
import { Service } from "models/schemas/service";
import { Link } from "models/schemas/link";

export type ServiceCardProps = {
    title: string,
    description: string,
    link?: Link
}

const ServiceCard: React.FC<ServiceCardProps> = ({ title, description, link }) => {
  return (
    <GlowCard link={link}>
        <Grid container alignItems={"space-between"}>
            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <Typography variant={"body1"} color={"textPrimary"} fontWeight={"bold"}>
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={"body2"} color={"textSecondary"} align={"left"}>
                        {description}
                    </Typography>
                </Grid>
            </Grid>
      </Grid>
    </GlowCard>
  );
};
export { ServiceCard };