import React from 'react';
import { Member } from "models/schemas/member";
import { Avatar, AvatarGroup, Badge, Card, CardContent, CardMedia, Grid, Typography, useTheme } from "@mui/material";
import Markdown from "markdown-to-jsx";
import { markdownOptions } from "components/options/markdown-options";
import { AwardBadge } from "components/layout/award-badge";

export type MemberCardProps = {
    member: Member;
}

const MemberCard: React.FC<MemberCardProps> = ({ member }) => {

    const theme = useTheme();

    return (
        <Grid container spacing={1}>
            <Grid item>
                <Badge
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    badgeContent={
                        <AvatarGroup>
                            {member.awards.map(award => (
                                <AwardBadge award={award} key={award.certification.name} size={35}/>
                            ))}
                        </AvatarGroup>
                    }
                >
                    <Avatar
                        variant={"rounded"}
                        src={member.image.url}
                        alt={member.image.alt}
                        sx={{ width: 100, height: 100 }}
                    />
                </Badge>

            </Grid>
            <Grid item container xs alignContent={"start"}>
                <Grid item>
                    <Typography fontWeight={"bold"}>{member.firstName} {member.surname}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={"body2"} color={"rgba(255,255,255,0.7)"}>{member.role}</Typography>
                </Grid>
                <Grid item>
                    <Markdown
                        options={{
                            ...markdownOptions,
                            overrides: {
                                p: {
                                    component: Typography,
                                    props: {
                                        variant: "body2",
                                        fontStyle: "italic",
                                        color: theme.palette.info.main
                                    }
                                }
                            }
                        }}>{member.biography.markdown}</Markdown>
                </Grid>
            </Grid>
        </Grid>
    )
}
export { MemberCard }