import React, { useEffect } from "react";
import { Box, Link as MuiLink } from "@mui/material";
import { Link as GatsbyLink } from "gatsby";

import "./glow-card.scss";
import { Props } from "models/props";
import { Link } from "models/schemas/link";

export type GlowCardProps = Props & {
  link?: Link;
}

const GlowCard: React.FC<GlowCardProps> = ({ link, children }) => {

  useEffect(() => {
    onmousemove = e => handleMouseMove(e);
  }, []);

  const handleMouseMove = (e: any) => {

    const { target } = e;

    const rect = target.getBoundingClientRect(),
      x = e.clientX - rect.left,
      y = e.clientY - rect.top;

    target.style.setProperty("--mouse-x", `${x}px`);
    target.style.setProperty("--mouse-y", `${y}px`);
  };

  return (
    <Box className={"card"} 
         component={link?.isInternal ? GatsbyLink : MuiLink} 
         to={link?.isInternal ? link?.url : undefined} 
         href={!link?.isInternal ? link?.url : undefined}
    >
      <Box className={"card-content"}>
          {children}
      </Box>
    </Box>
  );
};
export { GlowCard };